import { Component } from 'vue-property-decorator';
import PageRender from '../../../models/PageRender';
import { $router } from '@/main';
import jQuery from 'jquery';

@Component
export default class TeamFinanceComponent extends PageRender {
    public tabs: string[] = [
        'groups', 'members',
    ];
    public tab: string = null;
    public currentTabComponent: string = '';

    public async created() {
        await this.checkPeriodParam();
    }

    public async mounted() {
        this.changeTab(this.$route.params.tab);
    }

    public changeTab(tab: string): void {
        if (this.tabs.indexOf(tab) === -1) {
            tab = this.tabs[0];
        }

        if (tab !== this.$route.params.tab) {
            const params = this.$route.params;
            params.tab = tab;

            // tslint:disable-next-line: no-floating-promises
            $router.push({ name: 'me-directory', params });
        } else {
            this.doActiveClass(tab);
            this.currentTabComponent = 'me-directory-' + tab;
        }
    }

    public doActiveClass(tab: string) {
        this.$nextTick(() => {
            jQuery('.' + tab).addClass('active');
        });

    }
}
