import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { loginHelper, periodFilterHelper, financeAccountService, teamHelper, teamService, memberHelper, roleHelper, transferService } from '@/main';
import Group from '../../../models/Group';
import PageRender from '../../../models/PageRender';
import { FinanceAccount, Distribution } from '../../../models/Interfaces';
import FinanceTotal from '../../../models/FinanceTotal';
import DistributedInvoice from '../../../models/DistributedInvoice';
import FinanceMe from '../../../models/FinanceMe';
import moment from 'moment';
import Transfer from '../../../models/Transfer';
import { functions } from '@/helpers/functions';
import TransferModalComponent from '@/components/modals/transfer-modal';
import { TransferType } from '@/models/TransferType';
import to from 'await-to-js';
import Team from '@/models/Team';
import { Person } from '@/models/Person';
import { periodModule } from '@/store/modules/period';

@Component
export default class FinanceComponent extends PageRender {
    public tabs: string[] = ['overview', 'compensations', 'my-invoices', 'expenses', 'transfers'];
    public activeTab: number = 0;
    public meFinance: FinanceMe;
    public meFinancePrevious: FinanceMe;
    public financeAccount: FinanceAccount = {} as FinanceAccount;
    public currentUser: Person = {} as Person;

    public groups: Group[] = [];
    public isLoaded: boolean = false;
    public externalTransfer: Transfer = new Transfer();
    public editMode: boolean = false;
    public editIsLoading: boolean = false;
    public amount: number = 0;
    public teams: Team[] = [];
    public members: Person[] = null;
    public team: string = '';
    public teamId: number;
    public expenses: DistributedInvoice[] = [];

    public transfer: Transfer = new Transfer();
    public receivingAccounts: FinanceAccount[] = [];
    public type: TransferType = TransferType.PersonToPerson;

    get transferTitle() {
        return `Move your P&L from ${teamHelper.getTeamName(teamHelper.getTeamId())} to another of your teams`;
    }

    public compensationColumns: any[] = [
        { cell: this.renderGroupFinanceLink, title: 'GROUP_NAME' },
        { cell: this.formatCompensationAmount, title: 'COMPENSATION' },
        { cell: this.renderEnergyDedication, title: 'ENERGY_DEDICATION' },
        { cell: this.formatFinancialCompensationAmount, title: 'FINANCIAL_COMPENSATION' },
    ];

    public invoiceColumns: any[] = [
        { cell: this.renderInvoicePopoverDistributionGrid, title: 'INVOICE' },
        { cell: this.getAmountDistributedToMe, title: 'AMOUNT' },
        { cell: this.renderInvoiceDate, title: 'DATE' },
    ];

    public expenseColumns: any[] = [
        { cell: this.renderInvoicePopoverDistributionGrid, title: 'INVOICE' },
        { cell: this.getAmountDistributedToMe, title: 'AMOUNT' },
        { cell: this.renderBoolean, title: 'IS_RECURRING' },
        { cell: this.renderInvoiceDate, title: 'DATE' },
    ];

    public personTransfersColumns: any[] = [
        { cell: this.renderTransferAmount, title: 'AMOUNT', width: 100 },
        { field: 'reason', title: 'DESCRIPTION' },
        { cell: this.renderMemberBy, title: 'SENDER', width: 250 },
        { cell: this.renderMemberFor, title: 'RECEIVER', width: 250 },
        { cell: this.renderMemberInitiator, title: 'INITIATOR', width: 250 },
        { cell: this.renderActions, title: 'ACTIONS', width: 120 },
    ];

    public $refs!: {
        transferGrid;
        transferPAndLModal: TransferModalComponent;
    };

    public isTransferValid: any = {
        amount: true,
        sender: true,
        receiver: true,
        reason: true,
        internalAmount: true,
        team: true,
    };

    public async created() {
        await this.checkPeriodParam();

        this.changeTab(this.$route.params.tab);
        this.currentUser = loginHelper.getUser();
        await this.calculateFinanceTotal();

        this.isLoaded = true;
    }

    public changeTab(tab: string): void {
        this.activeTab = this.tabs.indexOf(tab) >= 0 ? this.tabs.indexOf(tab) : 0;
    }

    public tabChanged(currentIndex) {
        const route = this.$route;
        const newTab = this.tabs[currentIndex];
        if (newTab !== route.params.tab) {
            route.params.tab = newTab;
            return this.$router.push(route);
        }
    }

    public async calculateFinanceTotal() {
        const personId = this.currentUser.personId;

        this.teamId = teamHelper.currentTeamId;

        const period = periodModule.selectedPeriod;
        const previousPeriod = periodFilterHelper.previousMonth(period);

        this.financeAccount = await financeAccountService.getAccount(teamHelper.currentTeam.id, personId, period);
        const accountId = this.financeAccount.accountId;
        this.teams = await teamService.getTeams();
        this.receivingAccounts = [this.financeAccount];

        const financeTotal = new FinanceTotal(this.teamId);
        this.meFinance = await financeTotal.getMeFinance(personId, accountId, period, previousPeriod);
        this.groups = this.meFinance.groups;
        this.members = await memberHelper.getMembers();

        this.meFinance.personTransfers.forEach((transfer) => {
            transfer.receiverTeam = this.teams.find((x) => x.teamId === transfer.receiverTeam.teamId);
            transfer.senderTeam = this.teams.find((x) => x.teamId === transfer.senderTeam.teamId);
        });

        this.expenses = [...this.meFinance.extras, ...this.meFinance.expenses];
    }

    public async reloadData() {
        this.isLoaded = false;
        await this.calculateFinanceTotal();
        this.isLoaded = true;
    }

    public getAmount(item: DistributedInvoice): string {
        let amount = 0;
        item.distributions.forEach((distribution: Distribution) => {
            if (distribution.account && distribution.account.relatedEntityId === loginHelper.getUser().personId) {
                amount += distribution.amount;
            }
        });

        return amount.toFixed(2);
    }

    public amountUpdated(amount) {
        this.amount = amount;
    }

    public formatCompensationAmount(h, _, row) {
        const props = { value: row.dataItem.getTotalAmount() };
        return h(Vue.component('number-formatter'), { props });
    }

    public formatFinancialCompensationAmount(h, _, row) {
        return h('td', [row.dataItem.financialCompensation()]);
    }

    public getAmountDistributedToMe(h, _, row) {
        let amount = 0;
        const distributions = functions.getPersonDistributions(this.currentUser.personId, row.dataItem.distributions);
        distributions.forEach((distribution) => {
            amount += distribution.amount;
        });

        const props = { value: amount };
        return h(Vue.component('number-formatter'), { props });
    }

    public renderInvoiceDate(h, _, row) {
        return h('td', [moment(row.dataItem.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')]);
    }

    public async openTransferModal() {
        await this.$refs.transferPAndLModal.init();
    }

    public otherTeams(): any[] {
        const self = this;

        const teamIds = [];
        if (self.currentUser && self.currentUser.teamMemberships && self.currentUser.teamMemberships.length > 0) {
            self.currentUser.teamMemberships.forEach((tms) => {
                teamIds.push(tms.id);
            });
        }
        const list = this.teams.filter((team) => team.teamKey !== self.team && teamIds.indexOf(team.teamId) > -1);

        return list;
    }

    public renderMemberInitiator(h, _, row) {
        let member = row.dataItem.initiatorPerson;

        if (!member) {
            member = this.members.find((m: Person) => m.personId === row.dataItem.initiator);
        }

        if (!member) {
            member = new Person();
            member.firstName = 'Not available';
        }

        return this.renderMember(h, member);
    }

    public renderActions(h, _, row): any {
        let actions = [];
        const userId = loginHelper.getUser().personId;
        const initiator = row.dataItem && typeof row.dataItem.initiator !== 'undefined' ? parseInt(row.dataItem.initiator) : 0;

        if (initiator === userId || roleHelper.isSiteAdmin() || roleHelper.isFinanceEmployee()) {
            actions = [
                { title: 'Edit', function: this.editTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
                { title: 'Delete', function: this.removeTransfer, disabled: periodFilterHelper.isCurrentPeriodClosed() },
            ];
        }

        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public async removeTransfer(item) {
        this.showPending('DELETE_TRANSFER_PENDING');
        const [err] = await to(transferService.deleteTransfer(item.transferId));
        if (err) {
            this.clearAndShowError('DELETE_TRANSFER_FAILED', err);
        }
        this.clearAndShowSuccess('DELETE_TRANSFER_SUCCESS');
        await this.reloadData();
    }

    public async editTransfer(item) {
        this.$refs.transferPAndLModal.show();

        Vue.nextTick(async () => {
            this.$refs.transferPAndLModal.editMode = true;
            await this.$refs.transferPAndLModal.initTransfer(item);
        });
    }
}
